:root {
  /* Colors */
  --primary-color: purple;
  --secondary-color: brown;
  --secondary-color-hover: rgb(143, 35, 35);
  --third-color: gray;
  --white: #ffffff;
  --black: #000000;
  --black-main: rgba(0, 0, 0, 0.911);
  --black-secondary: rgba(10, 10, 10, 0.911);
  --gray: #5e5d5d;

  /* Fonts */
  --primary-font-family: 'Jost', sans-serif;
}

html {
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: var(--primary-color);
  color: var(--white);
}
::-moz-selection {
  background: var(--primary-color);
  color: var(--white);
}

/* Colors */

.primary-color {
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.primary-back-color {
  background-color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-back-color {
  background-color: var(--secondary-color);
}

.green-color {
  color: green;
}

.primary-button-style {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.primary-button-style:hover {
  background-color: var(--secondary-color-hover);
  transition: all 0.3s ease-in-out;
}

.donate-button-style {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 8px 32px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.donate-button-style:hover {
  background-color: var(--secondary-color-hover);
  transition: all 0.3s ease-in-out;
  color: var(--white);
}

.gray-color {
  color: var(--gray);
}

.myClass {
  color: var(--primary-color);
}

.main_font {
  font-family: var(--primary-font-family);
}

/* Font Sized */
.small-font {
  font-size: 12px;
}

.medium-font {
  font-size: 14px;
}

.large-font {
  font-size: 16px;
}

.heading-h1-font {
  font-size: 30px;
}

.heading-h2-font {
  font-size: 25px;
}

.heading-h3-font {
  font-size: 20px;
}

.site-container {
  min-height: 100vh;
}

.navbar_style {
  background-color: rgb(240, 240, 240);
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke='rgba%28128, 0, 0, 1%29' stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  border-color: var(--primary-color);
}

main {
  flex: 1;
}

.my_link_navi {
  text-decoration: none;
  color: var(--primary-color);
}

.my_link_navi:hover {
  color: var(--secondary-color);
}

.my-link {
  text-decoration: none;
  color: var(--white);
}
.my-link:hover {
  color: #f0f0f0;
}

.my-link:focus {
  outline: none;
  color: #f0f0f0;
}

/* Home Slide */
.homeslide {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000049),
    url('../public/images/pads2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: top center;
  width: 100%;
  height: 85vh;
}

.homeslide2 {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000049),
    url('../public/images/19.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: center center;
  width: 100%;
  height: 85vh;
}

.homeslide3 {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000049),
    url('../public/images/pads7.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: center center;
  width: 100%;
  height: 85vh;
}

.aboutus_homescreen {
  background-color: #f9f9f9;
  padding: 80px 0;
}

.videosec_homescreen {
  background-color: #f9f9f9;
  padding: 20px 0 100px 0;
}

.missionvission_aboutscreen {
  padding: 100px 0;
}

.team_aboutscreen {
  background-color: #f9f9f9;
  padding: 100px 0;
}

.client_aboutscreen {
  padding: 80px 0;
  background-color: #f9f9f9;
}

.achieve_aboutscreen {
  /* padding: 40px 0; */
  background-color: var(--black-main);
  color: #fff;
}

.clients_homescreen {
  padding: 100px 0;
}

.contact_screen {
  background-color: #f9f9f9;
  padding-top: 80px;
  color: var(--primary-color);
}

.my_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.my_head {
  font-size: 70px;
}

.anchor_link {
  color: var(--white);
  text-decoration: none;
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 4px;
  border-style: none;
}

.anchor_link:hover {
  background-color: var(--secondary-color);
  border-style: none;
  color: var(--white);
}

.tetimonials_screen {
  background-color: var(--primary-color);
  padding-bottom: 60px;
}

.testimonials {
  cursor: pointer;
}

.testimonialslide {
  color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: top center;
  width: 100%;
  height: 65vh;
}

.testimonial_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 830px) {
  .my_head {
    font-size: 60px;
  }
}

@media screen and (max-width: 725px) {
  .my_head {
    font-size: 45px;
  }
}

/* Landing Containers */
.landing_container {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000025),
    url('../public/images/31.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: center center;
}

.contact_landing_container {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000049),
    url('../public/images/pads8.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: center;
}

.gallery_landing_container {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000049),
    url('../public/images/26.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: left center;
}

.services_landing_container {
  color: var(--white);
  background: linear-gradient(to top, #000000ba, #00000049),
    url('../public/images/pads.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-position: top center;
}

.landing_row {
  align-items: center;
  height: 90vh;
}

.landing_header {
  font-size: 69px;
  font-weight: bold;
}

.landing_nav {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .landing_header {
    font-size: 40px;
    text-align: center;
  }

  .landing_nav {
    text-align: center;
  }
}

/* Product Quality */
.top_bottom_10_padding {
  padding: 10px 0;
}

.top_bottom_40_padding {
  padding: 40px 0;
}

.product_icon {
  font-size: 40px;
  color: var(--secondary-color);
}

/* Contact Form */
.main_left_row_div {
  display: flex;
  padding: 15px 0;
}

.left_icon_div {
  flex: 1;
}

.left_icon_div {
  margin-right: 50px;
  border-radius: 50%;
  text-align: center;
  min-width: 80px;
  height: 80px;
}

.dim-red {
  background-color: rgba(255, 0, 0, 0.096);
}

.dim-blue {
  background-color: rgba(0, 0, 255, 0.178);
}

.dim-green {
  background-color: rgba(0, 128, 0, 0.192);
}

.left_icon_div i {
  font-size: 25px;
  margin-top: 25px;
}

.left_text_div {
  flex: 6;
}

/* Spacing */
.bottom_space {
  padding-bottom: 100px;
}

.bottom_space_40 {
  padding-bottom: 40px;
}

.top_space_20 {
  padding-top: 40px;
}

.top_space_10 {
  padding-top: 10px;
}

/* About Us Section */

.about_us_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Team */
.team_card {
  text-align: center;
}

/* Mission vission */
.vission_card {
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 17px;
}

.mission_card {
  background-color: var(--secondary-color);
  color: var(--white);
  font-size: 17px;
}

.mv_us_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.production_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: bottom center;
}

.team_img {
  object-fit: cover;
  object-position: top center;
}

@media screen and (max-width: 767px) {
  .mv_space_top {
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .mv_space {
    padding-top: 20px;
  }
}

/* Clients Section */
.main_client {
  padding: 10px;
}

/* achievement */

.achievement {
  display: flex;
  text-align: left;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .achievement {
    justify-content: space-evenly;
  }

  .pad-right {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .pad-right {
    padding-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .about_top_padding {
    padding-top: 50px;
  }

  /* .about_us_img {
    width: 100%;
    height: auto;
  } */
}

.client_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #f0f0f0;
  padding: 60px 0;
}

.client_img {
  width: 50%;
  object-fit: contain;
}

/* Donate */
.donate_container {
  background-color: #f0f0f0;
  padding: 70px 0;
}

.PayPalRow {
  text-align: center;
  align-items: center;
}

.paypal_txt h3 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
}

@media (max-width: 575px) {
  .paypal_txt {
    margin-bottom: 30px;
  }

  .donate-button-style {
    padding: 8px 50px;
  }
}

/* Footer Section */
.main_footer {
  background-color: var(--black-secondary);
  padding: 30px 0;
}
.social_links {
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.social_links_icon {
  margin-right: 20px;
}

.footer_nav_links {
  margin-left: -17px;
}

.my-link:hover {
  color: var(--secondary-color);
}

.bottom_footer {
  background-color: var(--black-main);
  color: #f0f0f0;
}

a {
  color: #f0f0f0;
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer_low {
    display: flex;
    flex-direction: column;
  }
  .text-left {
    text-align: center;
  }
  .text-end {
    display: none;
  }

  .footer_small_screen {
    padding-top: 35px;
  }
}

/* Map Section */

.mapouter {
  position: relative;
  text-align: right;
  height: 400px;
  width: 100%;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 400px;
  width: 100%;
}

.not-found {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 70vh;
}
